import * as React from "react"
import { colors, Grid, Link, Typography } from "@mui/material"
import { PostSnippet } from "../../types"
import { Icon } from "@iconify/react"

export interface FeaturePosts {
  featurePosts: PostSnippet[]
}

export const FeatureCard: React.FC<PostSnippet> = ({
  title,
  summary,
  href,
  img,
  imgAlt,
  tags,
  icon,
}) => {
  return (
    <Link href={href} sx={{ textDecoration: "none" }}>
      <Grid
        sx={{
          bgcolor: "background.paper",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          backgroundColor: colors.grey[200],
          p: 2,
          minWidth: 200,
          minHeight: 340,
          borderRadius: 1,
          mt: 4,
          "&:hover": {
            backgroundColor: colors.grey[300],
          },
          boxShadow: "0px 0px 0px rgb(0 0 0 / 30%)",
        }}
      >
        <Grid
          item
          sx={{
            mb: 2,
            borderRadius: "50%",
            width: "100px",
            height: "100px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 50,
            color: "#fff",
            backgroundImage:
              "linear-gradient(to right, rgb(99, 114, 255) 0%, rgb(92, 169, 251) 100%)",
          }}
        >
          <Icon icon={icon} />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ color: "text.primary" }}>
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            mt: 1,
          }}
        >
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {summary}
          </Typography>
        </Grid>
      </Grid>
    </Link>
  )
}
