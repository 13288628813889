import React, { FunctionComponent } from "react"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { PostSnippet } from "../../types"
import { styled } from "@mui/system"
import { Box, Button, Container } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"

const GatsbyImageNew = styled(GatsbyImage)({
  objectFit: "cover",
  layout: "fullWidth",
  zindex: 1,
  height: "100%",
  width: "100%",
})

export const Hero: FunctionComponent<PostSnippet> = ({
  title,
  summary,
  img,
  imgAlt,
}) => {
  return (
    <Paper
      sx={{
        position: "relative",
        backgroundColor: "grey.800",
        color: "#fff",
        mb: 4,
        height: "55vh",
        overflow: "hidden",
        borderRadius: 0,
      }}
    >
      <GatsbyImageNew image={img} alt={imgAlt || title} />

      <Grid
        container
        sx={{
          inset: 0,
          backgroundColor: "rgba(0,0,0, .7)",
          position: "absolute",
          zIndex: 2,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container>
          <Grid item>
            <Box>
              <Typography
                component="h1"
                variant="h3"
                color="inherit"
                gutterBottom
              >
                {title}
              </Typography>
              <Typography
                variant="h5"
                color="inherit"
                paragraph
                sx={{ display: "flex" }}
              >
                {summary}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  my: 2,
                  zIndex: 1,
                  background:
                    "linear-gradient(to right, #6372ff 70%, #5ca9fb 100%)",
                }}
                href="./contactus"
              >
                Get in Touch
              </Button>
            </Box>
          </Grid>
        </Container>
      </Grid>
    </Paper>
  )
}
