import { Icon } from "@iconify/react"
import { Grid, Typography } from "@mui/material"
import React from "react"

export interface TechStackProps {
  icon: string
  title: string
  size: number
}

export const TechStackItem: React.FC<TechStackProps> = ({
  icon,
  title,
  size,
}) => {
  return (
    <Grid
      container
      direction="column"
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 140,
        width: { xs: "10rem", md: "16rem" },
        m: 2,
      }}
    >
      <Grid
        item
        sx={{
          mb: 2,
          borderRadius: "50%",
          width: "120px",
          height: "120px",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          backgroundImage:
            "linear-gradient(to right, #6372ff 50%, #5ca9fb 100%)",
          fontSize: size,
          boxShadow: "10px 10px 10px rgb(0 0 0 / 10%)",
          minHeight: 80,
          "&:hover": {
            boxShadow: "10px 10px 10px rgb(0 0 0 / 20%)",
          },
        }}
      >
        <Icon icon={icon} />
      </Grid>
      <Typography sx={{ fontSize: 16, display: "flex", color: "#fff" }}>
        {title}
      </Typography>
    </Grid>
  )
}
