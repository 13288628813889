import React, { FunctionComponent } from "react"
import { PostSnippet } from "../../types"
import { Grid, Box, Typography, Paper, Link } from "@mui/material"
import { Icon } from "@iconify/react"

export interface PostList {
  posts: PostSnippet[]
}

export const PostListItem: FunctionComponent<PostSnippet> = ({
  href,
  title,
  summary,
  icon,
}) => {
  return (
    <Link href={href} sx={{ textDecoration: "none" }}>
      <Paper
        sx={{
          color: "#fff",
          fontSize: 50,
          p: 2,
          flexGrow: 1,
          boxShadow: "10px 10px 10px rgb(0 0 0 / 25%)",
          background: "linear-gradient(135deg, #5ca9fb 110px, #0354AB 0)",
          // "linear-gradient(135deg, teal 110px, #253254 0) top right",
          "&:hover": {
            cursor: "pointer",
            background: "#5ca9fb",
            color: "#0354AB",
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item sx={{ color: "#0354AB" }}>
            <Icon icon={icon} />
          </Grid>
          <Grid item xs={12} sm container ml={8}>
            <Grid item xs container direction="column">
              <Grid item xs>
                <Typography gutterBottom variant="h4" component="div">
                  {title}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {summary}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Link>
  )
}

export const OutsourceCard: FunctionComponent<PostList> = ({ posts }) => {
  return (
    <>
      {posts.map((post, index) => (
        <PostListItem {...post} key={index} />
      ))}
    </>
  )
}
