import { Grid, Typography, colors, Box } from "@mui/material"
import React, { FunctionComponent } from "react"
import { PostSnippet } from "../../types"
import { OutsourceCard } from "../outsourcing"

export interface RecentPosts {
  recentPosts: PostSnippet[]
}

export const RecentPosts: FunctionComponent<RecentPosts> = ({
  recentPosts,
}) => {
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 9,
          mb: 2,
          alignItems: "center",
        }}
        direction="column"
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
          variant="h4"
        >
          Interested in Outsourcing?
        </Typography>

        <Box
          sx={{
            borderBottom: 4,
            borderBottomColor: colors.blue[300],
            width: "15rem",
            mb: 1,
          }}
        ></Box>
        <Typography variant="h6" mb={3}>
          Best-in-class talent, competitive costs, incredible infrastructure
        </Typography>
      </Grid>

      <Grid
        container
        direction="column"
        sx={{
          justifyContent: "center",
          gap: { xs: 3 },
        }}
      >
        <OutsourceCard posts={recentPosts} />
      </Grid>
    </>
  )
}
