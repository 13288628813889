import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { Layout } from "../../components/layout"
import { PostSnippet } from "../../types"
import { FeaturePosts } from "../../components/featurePosts"
import { RecentPosts } from "../../components/recentPosts"
import { Hero } from "../../components/hero"
import { TechStack } from "../../components/techStack"
import { Box, Container } from "@mui/material"
import { IGatsbyImageData } from "gatsby-plugin-image"

export const pageQuery = graphql`
  {
    homePosts: allMarkdownRemark(
      limit: 1
      sort: { fields: [frontmatter___publishedDate], order: DESC }
      filter: { frontmatter: { homepage: { eq: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            tags
            title
            imgAlt
            description
            publishedDate
            icon
            img {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }

    featuredPosts: allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___publishedDate], order: DESC }
      filter: { frontmatter: { featured: { eq: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            tags
            title
            imgAlt
            description
            publishedDate
            icon
            img {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    recentPosts: allMarkdownRemark(
      limit: 10
      sort: { fields: [frontmatter___publishedDate], order: DESC }
      filter: { frontmatter: { outsource: { eq: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            tags
            title
            imgAlt
            description
            publishedDate
            img {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            icon
          }
        }
      }
    }
  }
`

interface Post {
  node: {
    id: string
    fields: {
      slug: string
    }
    frontmatter: {
      tags: string[]
      title: string
      imgAlt: string
      description: string
      publishedDate: string
      img: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
      icon: string
    }
  }
}

interface QueryData {
  homePosts: {
    edges: Post[]
  }
  featuredPosts: {
    edges: Post[]
  }
  recentPosts: {
    edges: Post[]
  }
}

interface Home {
  data: QueryData
}

const Home: FunctionComponent<Home> = ({ data }) => {
  const mapPostData = ({ node }: { node: Post["node"] }) => ({
    title: node.frontmatter.title,
    summary: node.frontmatter.description,
    href: node.fields.slug,
    img: node.frontmatter.img.childImageSharp.gatsbyImageData,
    imgAlt: node.frontmatter.imgAlt,
    tags: node.frontmatter.tags,
    publishedDate: new Date(node.frontmatter.publishedDate),
    icon: node.frontmatter.icon,
  })

  const featuredPostData: PostSnippet[] =
    data.featuredPosts.edges.map(mapPostData)
  const [otherFeature] = featuredPostData

  const homePostData: PostSnippet[] = data.homePosts.edges.map(mapPostData)
  const [homePostDt, ...rest] = homePostData

  const recentPostData: PostSnippet[] = data.recentPosts.edges.map(mapPostData)
  return (
    <Layout title="Home">
      <Box>
        <Hero {...homePostDt} />
        <Container>
          <FeaturePosts otherFeature={featuredPostData} />
        </Container>
        <TechStack />
        <Container>
          <RecentPosts recentPosts={recentPostData} />{" "}
        </Container>
      </Box>
    </Layout>
  )
}

export default Home
