import React, { FunctionComponent } from "react"

import { PostSnippet } from "../../types"

import { Box, colors, Grid, Typography } from "@mui/material"
import { FeatureCard } from "../feature-card"

export interface FeaturePosts {
  otherFeature: PostSnippet[]
}

export const FeaturePosts: FunctionComponent<FeaturePosts> = ({
  otherFeature,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 6,
          mt: 8,
          mb: 1,
        }}
      >
        <Box
          sx={{
            borderBottom: 4,
            borderBottomColor: colors.blue[300],
            width: "5rem",
            display: "flex",
            fontWeight: "bold",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4">SERVICES</Typography>
        </Box>
      </Box>

      <Grid
        container
        sx={{
          justifyContent: "center",
          paddingBottom: 5,
        }}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {otherFeature.map((featurePost, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            {<FeatureCard {...featurePost} />}
          </Grid>
        ))}
      </Grid>
    </>
  )
}
