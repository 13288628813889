import { Box, colors, Container, Grid, Typography } from "@mui/material"
import React from "react"
import { TechStackItem } from "./techStackItems"

export const TechStack: React.FC = () => {
  return (
    <>
      <Grid
        container
        sx={{
          backgroundImage:
            "linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)",
          justifyContent: "center",
          pb: 5,
        }}
      >
        <Container>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 8,
              mb: 3,
              alignItems: "center",
              color: "#fff",
            }}
            direction="column"
          >
            <Typography
              sx={{
                fontWeight: "bold",
                width: "15rem",
              }}
              variant="h4"
            >
              Our Tech Stack
            </Typography>
            <Box
              sx={{
                borderBottom: 4,
                borderBottomColor: colors.blue[200],
                width: "10rem",
                mb: 1,
              }}
            ></Box>
            <Typography variant="h6">
              Our team of development experts utilise a wide range of
              programming languages and frameworks to create intuitive software
              for your business as quickly and efficiently as possible.
            </Typography>
          </Grid>
        </Container>
        <Grid
          container
          sx={{
            justifyContent: "center",
          }}
        >
          <TechStackItem
            title="React / React Native"
            icon="akar-icons:react-fill"
            size={40}
          />
          <TechStackItem
            title="Angular"
            icon="akar-icons:angular-fill"
            size={40}
            key="akar-icons:angular-fill"
          />
          <TechStackItem
            title="Node"
            icon="fa-brands:node-js"
            size={40}
            key="fa-brands:node-js"
          />
          <TechStackItem
            title="Typescript"
            icon="cib:typescript"
            key="cib:typescript"
            size={40}
          />
          <TechStackItem
            title="Docker"
            icon="bxl:docker"
            key="bxl:docker"
            size={40}
          />
          <TechStackItem title="AWS" icon="bxl:aws" key="bxl:aws" size={60} />
          <TechStackItem
            title="Azure"
            icon="teenyicons:azure-solid"
            key="teenyicons:azure-solid"
            size={40}
          />
          <TechStackItem
            title="Microsoft .NET"
            icon="cib:dot-net"
            key="cib:dot-net"
            size={40}
          />
          <TechStackItem
            title="Poewr BI"
            icon="simple-icons:powerbi"
            key="simple-icons:powerbi"
            size={40}
          />
          <TechStackItem
            title="SharePoint"
            icon="mdi:microsoft-sharepoint"
            key="mdi:microsoft-sharepoint"
            size={40}
          />
          <TechStackItem
            title="Power App"
            icon="simple-icons:powerapps"
            key="simple-icons:powerapps"
            size={40}
          />
          <TechStackItem
            title="Power Automate"
            icon="simple-icons:powerautomate"
            key="simple-icons:powerautomate"
            size={40}
          />
          <TechStackItem
            title="Material UI"
            icon="simple-icons:mui"
            key="simple-icons:mui"
            size={40}
          />

          {/* <TechStackItem
            title="Microsoft Teams"
            icon="mdi:microsoft-teams"
            key="mdi:microsoft-teams"
            size={40}
          /> */}
          <TechStackItem
            title="MySql"
            icon="cib:mysql"
            key="cib:mysql"
            size={50}
          />
          <TechStackItem
            title="JQuery"
            icon="bxl:jquery"
            key="bxl:jquery"
            size={40}
          />
        </Grid>
      </Grid>
    </>
  )
}
